import React from "react";
import styled from "styled-components";

import SEO from "../components/Seo";
import Header from "../components/Header";
import SingleSearch from "../components/compare/search/SingleSearch";
import Offers from "../components/compare/offers/Offers";

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  background-color: #ffffff;
`;
const Container = styled.div`
  width: 100%;
  max-width: 1170px;
  position: relative;
  margin: 0 auto;
  padding: 1rem;
`;
const Title = styled.h1`
  font-size: 1.6rem;
  margin: 0 0 1rem 0;
  text-transform: uppercase;
  @media (max-width: 576px) {
    font-size: 1.3rem;
  }
`;

export default () => {
  return (
    <>
      <SEO
        title={`Location utilitaire ${process.env.REACT_APP_VILLE}`}
        lang="fr"
        description={`Louer un utilitaire à ${process.env.REACT_APP_VILLE} et sa région`}
      />
      <Header ville={process.env.REACT_APP_VILLE} />
      <SingleSearch />
      <Wrapper>
        <Container>
          <Title>LOCATION UTILITAIRE {process.env.REACT_APP_VILLE}</Title>
          <p>
            Vous cherchez à louer un utilitaire sur Lyon ou sa région ? Vous
            êtes sur le bon site{"\u00A0"}!
          </p>
          <p>
            Notre site compare les prix de nombreux loueurs d'utilitaires ainsi
            que la location utilitaire entre particuliers présents sur Paris.
            L'objectif est de vous proposer un maximum d'options à Paris afin
            que vous trouviez l'utilitaire répondant à votre besoin et surtout
            au meilleur rapport qualité/prix.
          </p>
          <p>
            Inutile d'aller sur chaque site internet des loueurs, nous vous
            présentons leurs offres en temps réel sur notre site. Vous accéderez
            donc à une multitude de locations utilitaires sur Paris en lançant
            une recherche dans notre moteur. Aidez vous de la carte interactive
            qui vous présente la localisation des agences ou véhicules afin de
            faciliter votre décision.
          </p>
          <p>
            Faites votre choix parmi les résultats, vous serez ensuite redirigé
            vers le site du loueur via lequel vous pourrez louer un utilitaire
            sur Paris en étant garanti d'avoir trouvé le meilleur deal possible
            {"\u00A0"}!
          </p>
        </Container>
      </Wrapper>
      <Offers />
    </>
  );
};
